<template>
  <div>
    <div class="tag">{{ tagText }}</div>
    <div class="d-flex flex-row gap-5 align-items-center">
      <div class="dates">
        {{ timelines.start_time | moment('DD MMMM, dddd') }}
      </div>
      <div class="cs-textstyle-paragraph">
        <i class="cs-icons-clock mr-1"></i
        >{{ timelines.start_time | moment('HH:mm A') }} -
        {{ timelines.end_time | moment('HH:mm A') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timelines: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tagText() {
      const today = new Date().setHours(0, 0, 0, 0);
      const trackDate = new Date(this.timelines.start_time).setHours(
        0,
        0,
        0,
        0
      );

      if (today === trackDate) {
        return 'Today';
      }
      if (today - trackDate === 24 * 60 * 60 * 1000) {
        return 'Yesterday';
      }

      return 'Past';
    },
  },
};
</script>

<style scoped>
.dates {
  background: rgba(205, 228, 255, 0.6);
  border-left: 3px solid deepskyblue;
  padding: 8px 20px 8px 20px;
  width: 200px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--cs-primary-base);
}
.tag {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: var(--cs-gray-03);
  margin-bottom: 10px;
}
</style>
