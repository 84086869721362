<template>
  <div>
    <cs-modal :show="show" @close="onClose" layout="medium">
      <div slot="header">
        <template v-if="notification && notification.id">Edit</template
        ><template v-else>Create</template> Notification
      </div>
      <div slot="body" class="d-flex flex-column mt-4 gap-3">
        <cs-input
          v-model="title"
          label="Title"
          :max-length="maxLength"
          required
        />
        <cs-date-input
          v-model="date"
          label="Date"
          required
          :invalid="!validDateTime"
        >
          <cs-input-note
            slot="input-notes"
            :type="validDateTime ? 'info' : 'error'"
            label="Date and time must be in the future"
          />
        </cs-date-input>
        <cs-time-input
          v-model="time"
          label="Time"
          required
          :invalid="!validDateTime"
        >
          <cs-input-note
            slot="input-notes"
            :type="validDateTime ? 'info' : 'error'"
            label="Date and time must be in the future"
          />
        </cs-time-input>
        <cs-textarea
          required
          v-model="description"
          label="Message"
          :max-length="maxLength"
        ></cs-textarea>
        <cs-file-input
          :application-id="gxAppId"
          accept-type="image/*"
          v-model="picture"
          label="Image"
          change-file-label="Change Image"
        />
        <cs-input label="Link" v-model="link">
          <cs-input-note
            slot="input-notes"
            type="info"
            label="If a link is included, the notification will include a button to open the link"
          />
        </cs-input>
        <cs-button
          class="save-btn"
          corners="rounded"
          variant="primary"
          :disabled="!isValid"
          @click="submit"
          >Save</cs-button
        >
      </div>
    </cs-modal>
  </div>
</template>

<script>
import CreateNotification from '@/gql/notification/CreateNotification.gql';
import UpdateNotification from '@/gql/notification/UpdateNotification.gql';

const gxAppId = process.env.VUE_APP_GX_APP_ID;

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    notification: {
      type: Object,
    },
  },
  data() {
    return {
      gxAppId,
      maxLength: 1000,
      title: '',
      description: '',
      date: this.$moment(new Date()).add('2 days').format('YYYY-MM-DD'),
      time: this.$moment(new Date()).format('HH:mm'),
      picture: '',
      link: '',
    };
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        console.log('show');
        this.setDefault();
      },
    },
  },
  computed: {
    validDateTime() {
      const date = this.$moment(`${this.date} ${this.time}`);
      return date > this.$moment(new Date());
    },
    isValid() {
      console.log(this.title, this.description);
      return (
        this.title.length > 0 &&
        this.description.length > 0 &&
        this.validDateTime
      );
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setDefault() {
      if (this.notification) {
        this.title = this.notification.title || '';
        this.description = this.notification.description || '';
        this.date = this.$moment(
          new Date(this.notification.scheduled_at)
        ).format('YYYY-MM-DD');
        this.time = this.$moment(this.notification.scheduled_at).format(
          'HH:mm'
        );
        this.picture = this.notification.picture || '';
        this.link = this.notification.link || '';
      } else {
        this.title = '';
        this.description = '';
        this.date = this.$moment(new Date())
          .add(1, 'hour')
          .format('YYYY-MM-DD');
        this.time = this.$moment(new Date()).add(1, 'hour').format('HH:mm');
        this.picture = '';
        this.link = '';
      }
    },
    async submit() {
      const date = this.$moment(`${this.date} ${this.time}`);
      if (date < this.$moment(new Date())) {
        this.$toast.open({
          message: 'Date and time must be in the future',
          type: 'error',
          duration: 2000,
          dismissible: true,
          position: 'top-right',
        });
        return;
      }
      this.saveNotification();
    },
    async saveNotification() {
      const notification = {
        title: this.title,
        description: this.description,
        scheduled_at: this.$moment(`${this.date} ${this.time}`).format(),
        link: this.link,
        picture: this.picture,
      };
      try {
        if (this.notification && this.notification.id) {
          await this.$apollo.mutate({
            mutation: UpdateNotification,
            variables: {
              ...notification,
              id: this.notification.id,
            },
          });
        } else {
          await this.$apollo.mutate({
            mutation: CreateNotification,
            variables: notification,
          });
        }
        this.onClose();
        this.$emit('update-list');
      } catch (error) {
        this.$toast.open({
          message: 'Something went wrong, please try again or contact support',
          type: 'error',
          duration: 2000,
          dismissible: true,
          position: 'top-right',
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
* >>> .cs-input__label-text {
  color: var(--cs-gray-06);
  font-weight: 500;
}
.save-btn {
  display: flex;
  align-self: flex-end;
}
.cs-file-input >>> label {
  display: block;
}
</style>
