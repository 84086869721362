<template>
  <div class="cdn-control-bar">
    <cs-search-bar
      v-model="search"
      class="cdn-control-bar-search-bar"
      :placeholder="placeholder"
    />
    <cs-button
      v-if="$listeners.refresh"
      class="list-control-bar-refresh-button cdn-refresh-button"
      icon="cs-icons-pull-refresh"
      fill="clear"
      corners="rounded"
      variant="secondary"
      @click="$emit('refresh')"
    />
    <slot> </slot>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      search: this.value || '',
    };
  },
  watch: {
    search() {
      this.$emit('input', this.search);
    },
  },
};
</script>
<style scoped>
.cdn-control-bar {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}
.cdn-control-bar-search-bar {
  flex: 1;
}
.cs-search-bar.cdn-control-bar-search-bar {
  border: 1px solid var(--cs-gray-02);
}
</style>
