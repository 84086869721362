<template>
  <div class="wrapper p-3">
    <div class="d-flex justify-content-between mb-3">
      <div class="cs-textstyle-section-heading">Analytics</div>
    </div>
    <cs-page-tabs v-model="index" layout="large" class="mb-3">
      <cs-page-tab label="History" />
      <cs-page-tab label="Top Songs" />
    </cs-page-tabs>
    <app-history v-show="index === 0" />
    <app-top-songs v-show="index === 1" />
  </div>
</template>

<script>
import AppHistory from '@/components/analytics/History.vue';
import AppTopSongs from '@/components/analytics/TopSongs.vue';
export default {
  components: {
    AppHistory,
    AppTopSongs,
  },
  data() {
    return {
      index: 0,
    };
  },
  methods: {},
};
</script>

<style scoped>
.create-btn-text {
  margin-left: 2px;
}
</style>
