<template>
  <b-container class="main" fluid>
    <b-row>
      <b-col class="left-sidebar" cols="12" sm="4" order="2" order-sm="1">
        <img :src="logo" alt="logo" class="" />
        <img :src="illustration" alt="logo" class="illustration-img" />
      </b-col>
      <b-col class="right-body" cols="12" sm="8" order="1" order-sm="2">
        <cs-card class="card-class">
          <div slot="body" class="card-body-class">
            <div class="card-body-class" v-if="loginMode">
              <div class="cs-textstyle-section-heading mode-heading">Login</div>
              <!-- <div class="cs-textstyle-paragraph">
                Login with your official Effect Radio credentials.
              </div>
              <cs-input
                class="mt-4"
                label="Email"
                placeholder="Enter email"
                v-model="email"
              />
              <cs-input
                class="mt-4"
                label="Password"
                type="password"
                v-model="password"
                placeholder="Enter password"
              /> -->
            </div>
            <!-- <div class="card-body-class" v-if="!loginMode">
              <div class="cs-textstyle-section-heading mode-heading">
                Register
              </div>
              <div class="cs-textstyle-paragraph">
                Create a new account for Effect Radio.
              </div>
              <cs-input
                class="mt-4"
                label="Email"
                placeholder="Enter email"
                v-model="email"
              />
              <cs-input
                class="mt-4"
                label="Password"
                type="password"
                v-model="password"
                placeholder="Enter password"
              />
              <cs-input
                class="mt-4"
                label="Name"
                v-model="name"
                placeholder="Enter name"
              />
            </div> -->
            <!-- <div class="forgot-password">Forgot Password?</div> -->
            <br />
            <!-- <cs-button
              corners="rounded"
              variant="primary"
              @click.prevent="loginMode ? loginWithEmail() : createAccount()"
              >Login</cs-button
            > -->

            <cs-login-button
              v-for="provider in providers"
              :key="provider"
              :network="provider"
              corners="rounded"
              class="cdn-login__button"
              @click="login(provider)"
            >
              {{
                provider === 'google'
                  ? 'Sign in with Google'
                  : 'Sign in with Microsoft'
              }}
            </cs-login-button>
            <br />

            <!-- <cs-button
              corners="rounded"
              fill="outline"
              variant="secondary"
              @click="loginMode = !loginMode"
              >{{
                loginMode ? 'Create New Account' : 'Back to Login'
              }}</cs-button
            > -->
          </div>
        </cs-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LoginWithEmail from '@/gql/general/LoginWithEmail.gql';
import CreateAccount from '@/gql/general/CreateAccount.gql';
const logo = require('@/assets/images/EffectRadioLogo.svg');
const illustration = require('@/assets/images/illustration.svg');
export default {
  data() {
    return {
      logo,
      loginMode: true,
      illustration,
      email: '',
      password: '',
      name: '',
      providers: ['google'],
      // providers: ['google', 'microsoft'],
    };
  },
  computed: {
    validated() {
      return this.email && this.password;
    },
  },
  methods: {
    async login(provider) {
      const providerId = provider.toUpperCase();
      const gxAppId = process.env.VUE_APP_GX_APP_ID;
      const callbackurl = location.hostname.includes('localhost')
        ? 'http://localhost:8081/auth.html'
        : 'https://admin.effectradio.app/auth.html';
      window.location = `https://services-graph.graphexpress.com/login?application=${gxAppId}&provider=${providerId}&callback=${callbackurl}`;
    },
    async loginWithEmail() {
      this.errorMessage = '';
      this.error = false;
      try {
        const { data } = await this.$apollo.query({
          query: LoginWithEmail,
          variables: {
            email: this.email,
            password: this.password,
          },
        });
        const token = data.login;
        localStorage.access_token = token;
        window.location.href = '/';
      } catch (error) {
        this.$toast.open({
          message: 'Check your email and password',
          type: 'error',
          duration: 2000,
          dismissible: true,
          position: 'top-right',
        });
      }
    },
    async createAccount() {
      this.errorMessage = '';
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CreateAccount,
          variables: {
            email: this.email,
            password: this.password,
            name: this.name,
          },
        });
        const token = data.gx_create_account;
        localStorage.access_token = token;
        window.location.href = '/';
      } catch (error) {
        this.$toast.open({
          message: 'Something went wrong, please try again',
          type: 'error',
          duration: 2000,
          dismissible: true,
          position: 'top-right',
        });
      }
    },
  },
};
</script>

<style scoped>
.main {
  background-color: var(--cs-default-lightest);
  height: 100vh;
}
.left-sidebar {
  padding: 12px !important;
  background: #063973;
  position: relative;
  height: 100vh;
  display: grid;
  place-content: center;
}

.illustration-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.right-body {
  display: grid;
  place-content: center;
}
.card-class {
  border: 1px solid #f5f7f6;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.card-body-class {
  display: flex;
  flex-direction: column;
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
  margin-bottom: 32px;
  color: var(--cs-gray-04);
}
.cdn-login__button {
  margin-top: 16px;
}
.action-btns {
  border-bottom: 1px solid var(--cs-gray-02);
  padding-bottom: 33px;
}
.login-btn {
  width: 230px;
}
.mode-heading {
  color: var(--cs-gray-07);
}
* >>> .cs-input__label-text {
  color: var(--cs-gray-05);
  font-weight: 500;
}
</style>
