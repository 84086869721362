<template>
  <b-container class="main" fluid>
    <b-row>
      <b-col class="left-sidebar" cols="12" sm="4" order="2" order-sm="1">
        <img :src="logo" alt="logo" class="" />
        <img :src="illustration" alt="logo" class="illustration-img" />
      </b-col>
      <b-col class="right-body" cols="12" sm="8" order="1" order-sm="2">
        <cs-card class="card-class">
          <div slot="body" class="card-body-class">
            <div class="card-body-class">
              <div class="cs-textstyle-section-heading mode-heading">
                Access Denied
              </div>
              <br />
              <div class="cs-textstyle-paragraph">
                Sorry, but you don't have access to this dashboard. Please
                contact support if you need access or login with an approved
                account
              </div>
              <br />
              <div>
                <cs-button @click="retryLogin">Login</cs-button> &nbsp;
                <cs-button @click="retryAccess" fill="outline">Retry</cs-button>
              </div>
            </div>
          </div>
        </cs-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const logo = require('@/assets/images/EffectRadioLogo.svg');
const illustration = require('@/assets/images/illustration.svg');
export default {
  data() {
    return {
      logo,
      illustration,
    };
  },
  methods: {
    async retryAccess() {
      window.location.href = window.location.origin;
    },
    async retryLogin() {
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.main {
  background-color: var(--cs-default-lightest);
  height: 100vh;
}
.left-sidebar {
  padding: 12px !important;
  background: #063973;
  position: relative;
  height: 100vh;
  display: grid;
  place-content: center;
}

.illustration-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.right-body {
  display: grid;
  place-content: center;
}
.card-class {
  border: 1px solid #f5f7f6;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.card-body-class {
  display: flex;
  flex-direction: column;
}
.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;
  margin-bottom: 32px;
  color: var(--cs-gray-04);
}
.cdn-login__button {
  margin-top: 16px;
}
.action-btns {
  border-bottom: 1px solid var(--cs-gray-02);
  padding-bottom: 33px;
}
.login-btn {
  width: 230px;
}
.mode-heading {
  color: var(--cs-gray-07);
}
* >>> .cs-input__label-text {
  color: var(--cs-gray-05);
  font-weight: 500;
}
</style>
