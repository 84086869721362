<template>
  <div
    class="d-flex flex-row justify-content-between track-item align-items-center"
  >
    <div class="d-flex flex-row gap-2" v-if="song">
      <img :src="song.picture || defaultPic" alt="track image" />
      <div class="d-flex flex-column">
        <div class="cs-textstyle-paragraph-bold d-flex flex-wrap">
          {{ song.title || 'Modern Christian Music' }}
        </div>
        <div class="cs-textstyle-paragraph-small">{{ song.artist }}</div>
      </div>
    </div>
    <div class="cs-textstyle-paragraph-bold">
      {{ reactionMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      required: true,
    },
    liked: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      defaultPic:
        'https://cdnrf.securenetsystems.net/file_radio/stations_large/EFXAAC/v5/album-art-default.png',
    };
  },
  computed: {
    song() {
      if (this.track) return this.track.track;
      return null;
    },
    reactionMessage() {
      if (!this.track) return '';
      const reactionKey = this.liked ? 'total_likes' : 'total_dislikes';
      const count = this.track[reactionKey] || 0;
      const word = this.liked ? 'like' : 'dislike';
      const plural = count === 1 ? '' : 's';
      return `${count} ${word}${plural}`;
    },
  },
};
</script>

<style scoped>
img {
  width: 65px;
  height: 65px;
}
.track-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 16px 16px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #e6eaea;
}
</style>
