<template>
  <cs-card>
    <b-row class="d-flex flex-row" slot="body" v-if="!$apollo.query.loading">
      <b-col cols="6">
        <cs-card class="content-cards">
          <div slot="body">
            <div class="cs-textstyle-item-heading mb-2">Radio History</div>
            <div class="search-wrapper">
              <i class="cs-icons-search search-icon"></i
              ><cs-input
                v-model="searchTrack"
                type="text"
                class="input-search"
                placeholder="Search"
              ></cs-input>
            </div>
            <div class="d-flex flex-column gap-2">
              <app-post-item
                v-for="track in filteredTracks"
                :key="track.id"
                :track="track.track"
                :selected="track.track_id === songId"
                @clicked-on-track="loadSingleTrack"
                :date-played="track.start_time"
              ></app-post-item>
            </div>
            <cs-button
              class="mt-2"
              variant="primary"
              size="small"
              fill="clear"
              @click="fetchMore"
              v-if="!loading"
            >
              <span class="mr-1">View More</span>
              <i class="cs-icons-expand-arrow-filled" />
            </cs-button>
            <cs-spinner v-else class="loader-class"></cs-spinner>
          </div>
        </cs-card>
      </b-col>
      <b-col cols="6">
        <cs-card class="content-cards">
          <div slot="body">
            <app-track-history v-if="songId" :id="songId"></app-track-history>
          </div>
        </cs-card>
      </b-col>
    </b-row>
  </cs-card>
</template>

<script>
import AppPostItem from '@/components/analytics/PostItem.vue';
import ListTracks from '@/gql/analytics/ListTracks.gql';
import AppTrackHistory from '@/components/analytics/TrackHistory.vue';
export default {
  components: {
    AppPostItem,
    AppTrackHistory,
  },
  data() {
    return {
      searchTrack: '',
      tracks: [],
      perPage: 10,
      currentPage: 0,
      songId: '',
      lastTrack: null,
      loading: false,
    };
  },
  beforeMount() {
    this.fetchMore();
  },
  computed: {
    filteredTracks() {
      const regex = new RegExp(this.searchTrack, 'i');
      return this.tracks.filter(
        (item) => regex.test(item.title) || regex.test(item.artist)
      );
    },
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(date.getUTCMilliseconds()).padStart(6, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00`;
    },
    async fetchMore() {
      this.loading = true;
      const timeoffset = this.lastTrack
        ? this.lastTrack.start_time
        : Date.now();
      const newTimeOffset = this.formatDate(timeoffset);

      this.currentPage += 1;
      const { data } = await this.$apollo.query({
        query: ListTracks,
        variables: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
          timeoffset: newTimeOffset,
        },
        fetchPolicy: 'no-cache',
      });
      this.tracks = [...this.tracks, ...data.tracks];
      this.songId = this.tracks[0].track_id;

      this.lastTrack = this.tracks[this.tracks.length - 1];
      this.loading = false;
    },
    loadSingleTrack(val) {
      console.log('val', val);
      this.songId = val;
    },
  },
};
</script>

<style scoped>
.content-cards {
  background: var(--cs-gray-01) !important;
  padding: 0px;
}
.search-wrapper {
  position: relative;
  border: 1px solid var(--cs-gray-02);
  background: var(--cs-gray-00);
  border-radius: 3px;
  margin-bottom: 16px;
}
.input-search {
  margin-left: 15px;
  width: 97%;
}

* >>> .cs-input__input {
  border: none !important;
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: var(--cs-gray-03);
}
</style>
