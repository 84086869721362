<template>
  <div>
    <app-gray-card v-if="notification && notification.length">
      <app-list-control-bar
        v-model="searchQuery"
        placeholder="Filter notifications"
        @refresh="refresh()"
      >
      </app-list-control-bar>
      <b-table
        :items="notification"
        :fields="fields"
        :filter="searchQuery"
        hover
        outlined
        table-variant="light"
        show-empty
        responsive
        thead-tr-class="eca-dashboard__table-head-row"
        tbody-tr-class="eca-dashboard__table-row"
        :busy="loading"
      >
        <div slot="emptyfiltered" class="cdn-table-empty">
          No matching notifications.
        </div>
        <template v-slot:cell(picture)="data">
          <img v-if="data.item.picture" :src="data.item.picture" alt="" />
        </template>
        <template v-slot:cell(scheduled_at)="data">
          <div>{{ data.item.scheduled_at | moment('DD MMM, YYYY') }}</div>
        </template>
        <template v-slot:cell(scheduled_time)="data">
          <div>{{ data.item.scheduled_at | moment('HH A z') }}</div>
        </template>
        <template v-slot:cell(sent)="data">
          <cs-tag class="notif-tag">Sent</cs-tag>
        </template>
      </b-table>
      <cs-pagination
        v-if="totalPages"
        :count="totalPages"
        :value="currentPage"
        layout="medium"
        @input="getJobsList"
        class="pagination-class"
      >
      </cs-pagination>
    </app-gray-card>
    <app-gray-card v-else>
      <cs-empty-state
        title="No notifications sent"
        description='Use the "Create new" button to configure a new notification'
      />
    </app-gray-card>
  </div>
</template>

<script>
import ListNotifications from '@/gql/notification/Notifications.gql';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
export default {
  components: {
    AppGrayCard,
    AppListControlBar,
  },
  props: {
    updateNotif: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      loading: false,
      notification: [],
      showDelete: false,
      showEditModal: false,
      selectedNotification: {},
      searchQuery: '',
      fields: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'picture',
          label: 'Image',
        },
        {
          key: 'description',
          label: 'Description',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'scheduled_at',
          label: 'Date',
        },
        {
          key: 'scheduled_time',
          label: 'Time',
        },
        {
          key: 'sent',
          label: 'Status',
        },
      ],
    };
  },
  beforeMount() {
    this.getJobsList(this.currentPage);
  },
  watch: {
    updateNotif() {
      this.getJobsList(this.currentPage);
      this.$emit('update-notif');
    },
  },
  methods: {
    async getJobsList(currentPage) {
      this.loading = true;
      this.currentPage = currentPage;
      const { data } = await this.$apollo.query({
        query: ListNotifications,
        variables: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage,
          sent: true,
        },
        fetchPolicy: 'no-cache',
      });
      if (data.notification) {
        this.calculateTotalPages(data.notification_aggregate.aggregate.count);
        this.notification = data.notification;
      }
      this.loading = false;
    },
    calculateTotalPages(length) {
      this.totalPages = length ? Math.ceil(length / this.perPage) : 0;
    },
    updateList() {
      this.getJobsList();
    },
    refresh() {
      this.updateList();
    },
  },
};
</script>

<style scoped>
.icons {
  display: flex;
  gap: 28px;
  font-size: 20px;
  color: var(--cs-gray-04);
}
.del-icon {
  color: var(--cs-danger-base);
}
.notif-tag {
  --cs-tag-color: var(--cs-success-base) !important;
  --cs-tag-text-color: var(--cs-gray-00) !important;
}
.pagination-class {
  background: var(--cs-gray-01);
  margin-top: -17px;
  border: 1px solid var(--cs-gray-02);
}
.b-table img {
  max-width: 80px;
  max-height: 50px;
}
</style>
