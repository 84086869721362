<template>
  <div class="wrapper p-3">
    <div class="d-flex justify-content-between mb-3">
      <div class="cs-textstyle-section-heading">Notifications</div>
      <cs-button corners="rounded" @click="showAddModal = true"
        ><i class="cs-icons-add-filled"></i
        ><span class="create-btn-text">Create new</span></cs-button
      >
    </div>
    <cs-page-tabs v-model="index" layout="large" class="mb-3">
      <cs-page-tab label="Scheduled Notifications " />
      <cs-page-tab label="Sent Notifications" />
    </cs-page-tabs>

    <app-scheduled-notifications
      v-show="index === 0"
      :updateNotif="updateList"
      @update-notif="toggleNotif"
    />
    <app-sent-notifications
      v-show="index === 1"
      @update-notif="toggleNotif"
      :updateNotif="updateList"
    />

    <app-edit-notification-modal
      @close="closeAddModal"
      @update-list="updateNotifications"
      :show="showAddModal"
      v-if="showAddModal"
    />
  </div>
</template>

<script>
import AppScheduledNotifications from '@/components/notifications/ScheduledNotifications.vue';
import AppEditNotificationModal from '@/components/notifications/EditNotificationModal.vue';
import AppSentNotifications from '@/components/notifications/SentNotifications.vue';
export default {
  components: {
    AppScheduledNotifications,
    AppEditNotificationModal,
    AppSentNotifications,
  },
  data() {
    return {
      index: 0,
      showAddModal: false,
      updateList: false,
    };
  },
  methods: {
    closeAddModal() {
      this.showAddModal = false;
    },
    updateNotifications() {
      this.updateList = true;
    },
    toggleNotif() {
      this.updateList = false;
    },
  },
};
</script>

<style scoped>
.create-btn-text {
  margin-left: 2px;
}
</style>
