<template>
  <div id="app">
    <b-row v-if="isLoggedIn && user && user.is_admin" class="g-0">
      <b-col md="1" class="d-none d-md-block">
        <app-sidebar />
        Sidebar
      </b-col>
      <b-col md="11" sm="12" xs="12" class="body-container">
        <div class="bottom d-sm-block d-md-none">
          <app-bottom-bar />
        </div>
        <app-topbar />
        <div class="body">
          <router-view />
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <router-view />
    </b-row>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import GetUser from '@/gql/user/GetUser.gql';
import AppSidebar from '@/components/general/Sidebar.vue';
import AppTopbar from '@/components/general/Topbar.vue';
import AppBottomBar from '@/components/general/Bottombar.vue';
export default {
  name: 'App',
  components: {
    AppSidebar,
    AppTopbar,
    AppBottomBar,
  },
  data() {
    return {
      userId: $auth.getUserId(),
      user: null,
    };
  },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          id: $auth.getUserId() || null,
        };
      },
    },
  },
  computed: {
    isLoggedIn() {
      return $auth.isLoggedIn() && this.user;
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location.href = '/';
    },
  },
};
</script>
<style>
#app {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.body-container {
  background: var(--cs-gray-01);
  height: 100vh;
}
.body {
  padding: 30px;
  height: calc(100% - 90px);
  overflow: auto;
}
@media (min-width: 768px) {
  .body {
    padding: 10px;
    height: calc(100% - 50px);
    overflow: auto;
  }
}
</style>
