<template>
  <div>
    <cs-modal :show="showDelete" class="ecs-transcoding-job" @close="onClose" layout="medium">
      <div slot="header">Delete Notification</div>
      <div slot="body" class="d-flex flex-column mt-4 gap-3">
        <div class="cs-textstyle-paragraph">
          Are you sure you want to remove the notification
          <span class="cs-textstyle-paragraph-bold">{{
            notification.title
          }}</span
          >?
        </div>
        <div class="cs-textstyle-paragraph-small">
          To confirm, type the title of the notification you wish to delete.
          This action can’t be reversed.
        </div>
        <cs-input
          v-model="title"
          label="Notification Title"
          required
        ></cs-input>
        <cs-button
          class="save-btn"
          corners="rounded"
          :disabled="!isValid"
          variant="danger"
          @click="submit"
          >Delete</cs-button
        >
      </div>
    </cs-modal>
  </div>
</template>

<script>
import DeleteNotification from '@/gql/notification/DeleteNotification.gql';
export default {
  props: {
    showDelete: {
      type: Boolean,
      required: true,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      title: '',
    };
  },
  computed: {
    isValid() {
      return this.title === this.notification.title;
    },
  },
  methods: {
    onClose() {
      this.$emit('close-delete');
    },
    async submit() {
      try {
        await this.$apollo.mutate({
          mutation: DeleteNotification,
          variables: {
            id: this.notification.id,
          },
        });
        this.$emit('close-delete');
        this.$emit('update-list');
      } catch (error) {
        this.$toast.open({
          message: 'Something went wrong, please try again',
          type: 'error',
          duration: 2000,
          dismissible: true,
          position: 'top-right',
        });
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
* >>> .cs-input__label-text {
  color: var(--cs-gray-06);
  font-weight: 500 !important;
  font-family: 'Roboto';
  font-size: 14px;
}
.save-btn {
  display: flex;
  align-self: flex-end;
}
* >>> .cs-input__label-text {
  color: var(--cs-gray-06);
  font-weight: 500;
}
</style>
