import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import AccessDenied from '@/views/login/AccessDenied.vue';
import Notifications from '@/views/notifications/NotificationList.vue';
import Analytics from '@/views/analytics/Analytics.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/notifications',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const openRoutes = ['Login', 'AccessDenied'];
  if (openRoutes.indexOf(to.name) > -1) {
    next();
  } else if ($auth.isLoggedIn()) {
    $auth
      .getAdminStatus()
      .then((isAdmin) => {
        if (isAdmin) next();
        else next({ name: 'AccessDenied' });
      })
      .catch((error) => {
        console.log('Router auth error:', error);
        next({ name: 'AccessDenied' });
      });
  } else {
    next({ name: 'Login' });
  }
});

export default router;
