<template>
  <div class="cdn-topbar">
    <img :src="logo" class="cdn-topbar__logo" alt="" />
    <div class="cdn-topbar__right">
      <app-user-avatar
        :name="username"
        :email="email"
        :profile-picture="user.picture"
      />
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import GetUser from '@/gql/user/GetUser.gql';
import AppUserAvatar from '@/components/topbar/UserAvatar.vue';

const logo = require('@/assets/images/EffectLogo.svg');

export default {
  components: {
    AppUserAvatar,
  },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          id: $auth.getUserId() || null,
        };
      },
    },
  },
  data() {
    return {
      search: '',
      user: {
        name: '',
        picture: '',
      },
      logo,
    };
  },
  computed: {
    docsUrl() {
      return process.env.VUE_APP_DOCS;
    },
    username() {
      return this.user && `${this.user.name}`;
    },
    email() {
      return this.user && this.user.email;
    },
  },
};
</script>

<style scoped>
.cdn-topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--cs-gray-00);
  border-bottom: 1px solid var(--cs-gray-02);
}
.cdn-topbar__logo {
  width: 121px;
  height: 49px;
  display: flex;
  align-self: center;
}
.cdn-topbar__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
  padding-right: 20px;
  font-weight: 700;
}
.cdn-topbar__right i {
  font-size: 24px;
  padding: 5px 10px 0px 5px;
  color: var(--cs-gray-04);
}
</style>
