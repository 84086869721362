<template>
  <div>
    <div class="cs-textstyle-item-heading mb-2">Track History</div>
    <cs-card>
      <div v-if="$apollo.queries.history.loading" slot="body">
        <cs-spinner class="loader-class"></cs-spinner>
      </div>
      <div
        slot="body"
        v-if="history && history.length > 0 && !$apollo.queries.history.loading"
      >
        <app-post-item
          class="item"
          track-history
          :track="currentHistory"
        ></app-post-item>
        <div class="timeline d-flex flex-column gap-3 mt-4">
          <app-track-timeline-item
            v-for="(time, $index) in timelines"
            :key="$index"
            :timelines="time"
          ></app-track-timeline-item>
        </div>
      </div>
    </cs-card>
  </div>
</template>

<script>
import TrackHistory from '@/gql/analytics/TrackHistory.gql';
import AppPostItem from '@/components/analytics/PostItem.vue';
import AppTrackTimelineItem from '@/components/analytics/TrackTimelineItem.vue';

export default {
  components: {
    AppPostItem,
    AppTrackTimelineItem,
  },
  props: {
    id: {
      required: true,
    },
  },
  apollo: {
    history: {
      query: TrackHistory,
      variables() {
        return {
          track_id: this.id || null,
        };
      },
    },
  },
  data() {
    return {
      history: [],
      isLoadingNewContent: false,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    '$apollo.query.loading': function (newValue) {
      this.isLoadingNewContent = newValue;
    },
  },
  computed: {
    currentHistory() {
      if (!this.history || this.history.length === 0) {
        return null;
      }

      return this.history[0];
    },
    timelines() {
      return this.history
        ? this.history.slice().sort((a, b) => {
            const startTimeA = new Date(a.start_time);
            const startTimeB = new Date(b.start_time);

            if (startTimeA > startTimeB) {
              return -1;
            }
            if (startTimeA < startTimeB) {
              return 1;
            }
            return 0;
          })
        : [];
    },
  },
};
</script>

<style scoped>
* >>> .track-item {
  border: none !important;
  padding: 0px;
}

.loader-class{
  display: grid;
  place-items: center;
}
</style>
