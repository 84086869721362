<template>
  <div class="er-sidebar">
    <div class="er-sidebar__logo-container">
      <img alt="Bucket Plus logo" :src="logo" class="er-sidebar__logo" />
    </div>
    <!-- Services -->
    <ul>
      <li v-for="(link, index) in navLinks" :key="index">
        <router-link
          :to="serviceRoute(link)"
          :class="isActiveRoute(link) ? 'er-sidebar__active-items' : ''"
          class="er-sidebar__none-active-items cs-textstyle-paragraph"
        >
          <span class="er-sidebar__items">
            <i v-if="link.icon" :class="link.icon" />
            {{ link.text }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
const logo = require('@/assets/images/EffectIconBlue.svg');

export default {
  props: {},
  data() {
    return {
      logo,
      navLinks: [
        {
          text: 'Notifications',
          path: 'notifications',
          name: 'Notifications',
          icon: 'cs-icons-notification-filled',
        },
        {
          text: 'Analytics',
          path: 'analytics',
          name: 'Analytics',
          icon: 'cs-icons-report-filled',
        },
      ],
    };
  },
  methods: {
    isActiveRoute(link) {
      if (!this.$route.name) return false;
      return this.$route.name.toLowerCase().includes(link.name.toLowerCase());
    },
    serviceRoute(link) {
      const path = link.extra ? `${link.path}/${link.extra}` : link.path;
      return `/${path}`;
    },
  },
};
</script>

<style scoped>
.er-sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--cs-primary-base);
  position: relative;
}
.er-sidebar__logo-container {
  /* height: 65px; */
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.er-sidebar__logo {
  /* height: 20px; */
  width: 100%;
  padding: 16px 0;
  padding-right: 20px;
  height: 90px;
}

ul {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  flex-direction: column;
  margin: 0px;
  justify-content: center;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}
a:hover {
  color: var(--cs-gray-00);
}
i,
.er-sidebar__item-icon-svg {
  font-size: 22px;
}
.er-sidebar__item-icon-svg {
  width: 20px;
  margin-top: -8px;
}
li {
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: center;
}
.er-sidebar__active-items {
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.er-sidebar__sub-active-items {
  color: var(--cs-gray-06) !important;
  border-color: inherit !important;
}
.er-sidebar__items {
  padding: 5px 20px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.er-sidebar__none-active-items {
  background: transparent;
  color: var(--cs-gray-03);
}
</style>
