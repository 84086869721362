<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    class="d-flex flex-row gap-2 track-item"
    :class="{ active: selected }"
    @click="clickedOnTrack"
  >
    <img :src="track.picture || defaultPic" alt="track image" />
    <div class="d-flex flex-column song-detail">
      <div class="cs-textstyle-paragraph-bold">
        {{ track.title || defaultTitle }}
      </div>
      <div class="cs-textstyle-paragraph-small">{{ track.artist }}</div>
      <div class="cs-textstyle-paragraph-extra-small" v-if="datePlayed">
        {{ datePlayed | moment('from', 'now') }}
        {{ datePlayed | moment('- DD MMM, YYYY') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    trackHistory: {
      type: Boolean,
      required: false,
    },
    datePlayed: {
      type: [String, Number],
    },
  },
  data() {
    return {
      defaultTitle: 'Modern Christian Music',
      defaultPic:
        'https://cdnrf.securenetsystems.net/file_radio/stations_large/EFXAAC/v5/album-art-default.png',
    };
  },
  methods: {
    clickedOnTrack() {
      this.$emit('clicked-on-track', this.track.id);
    },
  },
};
</script>

<style scoped>
img {
  width: 65px;
  height: 65px;
}
.track-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0px 16px 16px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #e6eaea;
}
.active {
  background: rgba(205, 228, 255, 0.58);
  border: 1px solid rgba(6, 57, 115, 0.43);
}

.song-detail {
  width: 100%;
}
</style>
