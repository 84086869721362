import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';

function getHeaders() {
  const headers = {};
  if (localStorage.access_token) {
    headers.Authorization = `Bearer ${localStorage.access_token}`;
  }
  headers['x-hasura-admin-secret'] = process.env.VUE_APP_GRAPHEXPRESS_SECRET;
  return headers;
}

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
  headers: getHeaders(),
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      File: {
        keyFields: ['key', 'created_at', 'start_time'],
      },
    },
  }),
});
