import jwtDecode from 'jwt-decode';
import apolloClient from '@/services/apollo';
import GetUser from '@/gql/user/GetUser.gql';

let userId;
let user;

function getUserId() {
  if (!userId && localStorage.access_token) {
    userId = jwtDecode(localStorage.access_token).id;
  }
  return userId;
}

function isLoggedIn() {
  // TODO: Validate token
  return !!localStorage.access_token;
}

function saveUser(userData) {
  user = userData;
}

function getAdminStatus() {
  return new Promise((resolve) => {
    if (!user) {
      const _userId = getUserId();
      if (!_userId) {
        resolve(false);
      } else {
        apolloClient
          .query({
            query: GetUser,
            variables: {
              id: _userId,
            },
          })
          .then(({ data }) => {
            if (data && data.user) {
              user = data.user;
              resolve(user.is_admin);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            console.error('Error loading user', err);
            resolve(false);
          });
      }
    } else {
      const adminStatus = user.is_admin;
      resolve(!!adminStatus);
    }
  });
}

export default {
  getUserId,
  isLoggedIn,
  getAdminStatus,
  saveUser,
};
