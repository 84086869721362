<template>
  <cs-card>
    <b-row class="d-flex flex-row" slot="body" v-if="!$apollo.query.loading">
      <b-col cols="6">
        <template v-if="likedSongs">
          <div class="cs-textstyle-informative-paragraph mb-4">
            Top Liked Songs
            <span class="er-liked-text"> ({{ likedSongs.length }} Songs) </span>
          </div>
          <cs-card class="content-cards">
            <div slot="body">
              <div class="d-flex flex-column gap-2">
                <app-reaction-item
                  :track="song"
                  :liked="true"
                  v-for="song in likedSongs"
                  :key="song.id"
                />
              </div>
              <div
                class="d-flex flex-column gap-2"
                v-if="likedSongs && !likedSongs.length"
              >
                <cs-empty-state description="No liked songs yet" />
              </div>
            </div>
          </cs-card>
        </template>
      </b-col>
      <b-col cols="6">
        <template v-if="dislikedSongs">
          <div class="cs-textstyle-informative-paragraph mb-4">
            Top Disliked Songs
            <span class="er-liked-text">
              ({{ dislikedSongs.length }} Songs)
            </span>
          </div>
          <cs-card class="content-cards">
            <div slot="body">
              <div class="d-flex flex-column gap-2">
                <app-reaction-item
                  :track="song"
                  v-for="song in dislikedSongs"
                  :key="song.id"
                />
              </div>
              <div
                class="d-flex flex-column gap-2"
                v-if="dislikedSongs && !dislikedSongs.length"
              >
                <cs-empty-state description="No disliked songs yet" />
              </div>
            </div>
          </cs-card>
        </template>
      </b-col>
    </b-row>
  </cs-card>
</template>

<script>
import AppReactionItem from '@/components/analytics/ReactionItem.vue';
import LikedSongs from '@/gql/analytics/LikedSongs.gql';
import DislikedSongs from '@/gql/analytics/DislikedSongs.gql';

export default {
  components: {
    AppReactionItem,
  },
  apollo: {
    likedSongs: {
      query: LikedSongs,
      result(data) {
        console.log('data', data);
      },
    },
    dislikedSongs: {
      query: DislikedSongs,
    },
  },
};
</script>

<style scoped>
.content-cards {
  background: var(--cs-gray-01) !important;
  padding: 0px;
}
.er-liked-text {
  color: var(--cs-gray-04);
}
</style>
